import { handleErrorWithSentry } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
import { dev } from '$app/environment';

Sentry.init({
	dsn: 'https://fbab073ff738e394385bf4c47672e779@o4508324769759232.ingest.de.sentry.io/4508324833525840',
	environment: dev ? 'development' : 'production',
	tracesSampleRate: 1.0
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
